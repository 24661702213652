$(document).ready(function () {
    $('#btnCode').click(function (e) {
        console.debug('Code button clicked');
        e.preventDefault();

        // create counter and get code
        var ctrStyle = $('input[name=ctrStyle]:checked').val();
        var ctrStart = $('#ctrStart').val();
        var _token = $('#_token').val();

        //console.debug(ctrStyle);
        //console.debug(ctrStart);
        //console.debug(_token);

        data = {ctrStyle: ctrStyle, ctrStart: ctrStart, _token: _token};

        $.post('/create', data)
            .done(function (data) {
                console.log('Request done');
                console.log(data);
                $('#ctrCode').html(data);

                // show code
                $('#ctrCode').show();

                // show copy button
                $('#btnCopy').show();

                // scroll to code
                $('html, body').animate({
                    scrollTop: $("#btnCopy").offset().top
                }, 2000);
            })
            .fail(function () {
                console.log('Request failed');
            })
            .always(function () {
                console.log('Request finished');
            });
    });

    // copy to clipboard logic with zeroclipboard
    ZeroClipboard.config( { swfPath: "/ZeroClipboard.swf" } );
    var client = new ZeroClipboard(document.getElementById("btnCopy"));

    client.on("ready", function (readyEvent) {
        //console.log("ZeroClipboard SWF is ready!");

        client.on( "copy", function (event) {
            var clipboard = event.clipboardData;
            clipboard.setData( "text/plain", $('#ctrCode').val() );;
        });

        client.on("aftercopy", function (event) {
            // `this` === `client`
            // `event.target` === the element that was clicked
            $('#btnCopy').html('OK');
            //console.log("Copied text to clipboard: " + event.data["text/plain"]);
        });
    });
});